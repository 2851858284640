
import { defineComponent } from 'vue';
import { Translation } from '@/models';
import { languageMixin } from '@/mixins/languageMixin';
import NoContent from '@/components/common/NoContent.vue';
import sortMixin from '@/mixins/sortMixin';
import SortCaret from '@/components/common/SortCaret.vue';
import TranslationImage from '@/components/common/TranslationImage.vue';
import { lessonDetailsMixin } from '@/mixins/lessonDetailsMixin';

export default defineComponent({
  components: { SortCaret, NoContent, TranslationImage },
  mixins: [languageMixin, sortMixin, lessonDetailsMixin],
  data () {
    return {
      vocabLevel: ''
    };
  },
  methods: {
    startStudy () {
      this.$router.push({ name: 'Lernen', params: { lessonIds: [this.lesson.id] } });
    },
    getBackgroundColor (level:number) {
      if (level > 80) {
        return 'levelGreen';
      } else if (level > 30 && level <= 80) {
        return 'levelOrange';
      } else {
        return 'levelRed';
      }
    }
  },
  computed: {
    sortedVocabs (): Translation[] {
      this.sortItems<Translation>(this.translations);
      return this.translations;
    }
  }
});
