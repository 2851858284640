
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SortCaret',
  props: {
    inactive: Boolean,
    dir: String
  }
});
