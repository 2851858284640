
import { defineComponent, PropType } from 'vue';
import { Translation } from '@/models';

export default defineComponent({
  name: 'ImageModal',
  data () {
    return {
      open: false
    };
  },
  props: {
    translation: {
      type: Object as PropType<Translation>,
      required: true
    },
    imageUrl: {
      type: String,
      required: true
    }
  }
});
