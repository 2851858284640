import { defineComponent } from 'vue';
import { backendUrl, Lesson, Translation } from '@/models';
import { showError } from '@/sweetalert2/templates';

export const lessonDetailsMixin = defineComponent({
  data () {
    return {
      lesson: { name: '' } as Lesson,
      translations: [] as Translation[]
    };
  },
  async mounted () {
    const id = parseInt(this.$route.params.id.toString()) as number;
    await this.getData(id);
  },
  methods: {
    playAudio (audioId:string) {
      const audio = new Audio(backendUrl + audioId);
      audio.play();
    },
    async getData (id: number): Promise<boolean> {
      try {
        this.lesson = await Lesson.getById(id);
        this.translations = await Translation.getTranslationsFromLesson(id);
        return true;
      } catch (e) {
        await showError(Lesson.LOAD_ERROR);
        return false;
      }
    }
  }
});
