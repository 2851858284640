import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-38a0acca")
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "caption" }
const _hoisted_3 = { key: 0 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("i", {
      class: "cursor fas fa-image fa-2x",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open=true))
    }),
    _createElementVNode("div", {
      class: _normalizeClass(['modal', _ctx.open ? 'd-block' : 'd-none'])
    }, [
      _createElementVNode("span", {
        class: "close",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open=false))
      }, "×"),
      _createElementVNode("img", {
        src: _ctx.imageUrl,
        class: "modal-content",
        alt: `Bild für ${_ctx.translation.fromName} - ${_ctx.translation.toName}`
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.translation.fromName) + " - " + _toDisplayString(_ctx.translation.toName) + " ", 1),
        (_ctx.translation.imageDescription)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("messages.License:")) + " " + _toDisplayString(_ctx.translation.imageDescription), 1))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 64))
}