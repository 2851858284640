import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6aa89202")
const _hoisted_1 = {
  class: "position-relative d-flex justify-content-around align-items-center",
  id: "image"
}
const _hoisted_2 = {
  key: 2,
  class: "text-center"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageModal = _resolveComponent("ImageModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.editingMode)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: "fas fa-upload fa-2x cursor",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.input.click()))
        }))
      : _createCommentVNode("", true),
    (_ctx.translation.imageUrl !== null && _ctx.translation.imageUrl !== undefined)
      ? (_openBlock(), _createBlock(_component_ImageModal, {
          key: 1,
          translation: _ctx.translation,
          imageUrl: _ctx.imgUrl
        }, null, 8, ["translation", "imageUrl"]))
      : _createCommentVNode("", true),
    (!_ctx.editingMode && (_ctx.translation.imageUrl === null || _ctx.translation.imageUrl === undefined))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("messages.No picture available")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      id: "inputId",
      ref: "input",
      type: "file",
      style: {"position":"fixed","top":"-100em"},
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addImage && _ctx.addImage(...args)))
    }, null, 544)
  ]))
}