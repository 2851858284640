import { defineComponent } from 'vue';

export default defineComponent({
  data () {
    return {
      currentSort: 'id',
      currentSortDir: 'asc'
    };
  },
  methods: {
    sortItems<T> (items: T[]) {
      items.sort((a, b) => {
        const modifier = this.currentSortDir === 'desc' ? -1 : 1;
        const aVal = a[this.currentSort as keyof T] as any;
        const bVal = b[this.currentSort as keyof T] as any;

        if (aVal === null || aVal === '' || (a as any)?.id === -1) { return -1; }
        if (bVal === null || aVal === '' || (b as any)?.id === -1) { return 1; }
        if (typeof aVal === 'number' && typeof bVal === 'number') { return (aVal - bVal) * modifier; }
        return ('' + aVal).toLowerCase().localeCompare(('' + bVal).toLowerCase()) * modifier;
      });
    },
    sort (key: string) {
      if (key === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = key;
    }
  }

});
