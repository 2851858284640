
import { defineComponent, PropType } from 'vue';
import { backendUrl, Translation } from '@/models';
import { mapState } from 'vuex';
import ImageModal from '@/components/common/ImageModal.vue';
import Swal from '@/sweetalert2';

export default defineComponent({
  name: 'TranslationImage',
  components: { ImageModal },
  emits: ['newImage'],
  props: {
    translation: { type: Object as PropType<Translation> },
    editingMode: { type: Boolean },
    index: Number
  },
  methods: {
    async addImage (event: Event) {
      const input = event.target as HTMLInputElement;
      const files = Array.from(input.files ?? []);
      if (files.length === 0) {
        return;
      }
      let { value: license } = await Swal.fire({ input: 'text', title: this.$t("messages.pic desc and license"), text: this.$t("messages.enter license") });
      const value = '' + license;
      const matches = value.match(/^<a href='(.*)'>(.*)<\/a>/);
      license = matches ? matches?.[2] + ' - ' + matches?.[1] : license;
      console.log(license);
      this.$emit('newImage', { translation: this.translation, file: files[0], index: this.index, license });
    }
  },
  computed: {
    ...mapState({ user: 'user' }),
    imgUrl () {
      const url = this.translation?.imageUrl || '';
      return url.includes('blob') ? url : backendUrl + url;
    }
  }
});
